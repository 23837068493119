<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>見積検索</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 見積日付 -->
                  <v-col cols="12" sm="4">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="mitsumoriDateRangeText"
                          label="見積日付"
                          append-icon="mdi-calendar"
                          readonly
                          clearable
                          :hint="`完全一致/範囲検索`"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="mitsumoriDate = []"
                          @click:append="menu1 = !menu1"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="mitsumoriDate"
                        range
                        @input="menu1 = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- 作成日付 -->
                  <!-- TODO 開始と終了分けた方が良いかも（実装はrangeが楽か） -->
                  <!-- TODO 書式設定。データに合わせるか、見た目はスラッシュ区切りで整えて登録時に数字のみにするか -->
                  <v-col cols="12" sm="4">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="sakuseiDateRangeText"
                          label="作成日付"
                          append-icon="mdi-calendar"
                          readonly
                          clearable
                          :hint="`完全一致/範囲検索`"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="sakuseiDate = []"
                          @click:append="menu2 = !menu2"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="sakuseiDate"
                        range
                        @input="menu2 = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- 見積番号開始 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="mitsumoriNoFrom"
                      label="見積番号開始"
                      :rules="[number, limit_length8]"
                      counter="8"
                      clearable
                      persistent-hint
                      :hint="`完全一致/範囲検索`"
                      hide-spin-buttons
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 見積番号終了 -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="mitsumoriNoTo"
                      label="見積番号終了"
                      :rules="[number, limit_length8]"
                      counter="8"
                      clearable
                      hide-spin-buttons
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 得意先名 -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="tokuisakiName"
                      label="得意先名"
                      :rules="[limit_length50]"
                      counter="50"
                      clearable
                      persistent-hint
                      :hint="`部分一致`"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 件名 -->
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="kenmeiName"
                      label="件名"
                      :rules="[limit_length60]"
                      counter="60"
                      clearable
                      persistent-hint
                      :hint="`部分一致`"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 検索 -->
              <v-btn color="secondary" v-on:click="submitSearch" rounded>
                <v-icon left> mdi-magnify </v-icon>検索
              </v-btn>
              <!-- <span v-if="success">バリデーションOK！</span> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- 見積検索明細データエリア -->
          <estimate-search-data-table :estimates="estimates" :tableLoading="tableLoading"/>
          <!-- ●TODO sortablejs等で行をドラッグアンドドロップで入れ替えできないか？ -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import EstimateSearchDataTable from "../components/EstimateSearchDataTable.vue";
import { API, graphqlOperation } from 'aws-amplify'
import { listV_MITSUMORI_DATA_WITH_MEISAI } from '@/graphql/queries';
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
export default {
  name: "EstimateSearch",

  components: {
    EstimateSearchDataTable,
  },

  data: () => ({
    // 見積日付
    // 2か月前の1日
    mitsumoriDate: [
      new Date(
        new Date(
          new Date(
            Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
            .setMonth(new Date().getMonth() -2))
            .setDate(1))
            .toISOString()
            .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    menu1: false,
    // 作成日付
    // 2か月前の1日
    sakuseiDate: [
      new Date(
        new Date(
          new Date(
            Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
            .setMonth(new Date().getMonth() -2))
            .setDate(1))
            .toISOString()
            .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    menu2: false,

    // 見積番号開始
    mitsumoriNoFrom: null,
    // 見積番号終了
    mitsumoriNoTo: null,

    // 得意先名
    tokuisakiName: "",
    // 件名
    kenmeiName: "",

    // 送信が成功したかどうかのフラグ
    success: false,

    // チェックボックス
    selected: [],

    // Datatablesのデータ
    items: [],
    estimates: [],
    // 編集インデックス
    editedIndex: -1,
    // データテーブルローディングフラグ
    tableLoading: false,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大2文字
    limit_length2: (value) =>
      (!value || value.length <= 2) || "2文字以内で入力してください",
    // 文字数の制約 最大7文字
    limit_length7: (value) =>
      (!value || value.length <= 7) || "7文字以内で入力してください",
    // 文字数の制約 最大8文字
    limit_length8: (value) =>
      (!value || value.length <= 8) || "8文字以内で入力してください",
    // 文字数の制約 最大13文字
    limit_length13: (value) =>
      (!value || value.length <= 13) || "13文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      (!value || value.length <= 20) || "20文字以内で入力してください",
    // 文字数の制約 最大40文字
    limit_length40: (value) =>
      (!value || value.length <= 40) || "40文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      (!value || value.length <= 50) || "50文字以内で入力してください",
    // 文字数の制約 最大60文字
    limit_length60: (value) =>
      (!value || value.length <= 60) || "60文字以内で入力してください",
    // 文字数の制約 最大80文字
    limit_length80: (value) =>
      (!value || value.length <= 80) || "80文字以内で入力してください",
    // 文字数の制約 最大150文字
    limit_length150: (value) =>
      (!value || value.length <= 150) || "150文字以内で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]+$/.test(value) || "数値で入力してください",
  }),
  computed: {
    mitsumoriDateRangeText: {
      get() {
        let rangeText = null;
        if (this.mitsumoriDate.length > 0) {
          if (this.mitsumoriDate[0] === this.mitsumoriDate[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.mitsumoriDate[0];
          } else if (this.mitsumoriDate[0] > this.mitsumoriDate[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.mitsumoriDate[1]} ~ ${this.mitsumoriDate[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.mitsumoriDate.join(" ~ ");
          }
        }
        return rangeText;
      },
      set() {
        // console.log("set： value = " + value);
      },
    },
    sakuseiDateRangeText: {
      get() {
        let rangeText = null;
        if (this.sakuseiDate.length > 0) {
          if (this.sakuseiDate[0] === this.sakuseiDate[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.sakuseiDate[0];
          } else if (this.sakuseiDate[0] > this.sakuseiDate[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.sakuseiDate[1]} ~ ${this.sakuseiDate[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.sakuseiDate.join(" ~ ");
          }
        }
        return rangeText;
      },
      set() {
        // console.log("set： value = " + value);
      },
    },
  },
  created() {
    // 初期化処理の実行
    this.initialize();
  },
  mounted() {
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ローディングOFF
    this.$store.commit("setLoading", false);
  },
  methods: {
    // 初期表示時処理
    async initialize() {
      // テーブルローディングON
      this.tableLoading = true;

      // 明細データ取得処理
      // WHERE句生成
      const whereOptions = this.makeWhereOptions(true);
      // console.info({whereOptions});
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let rowData = await this.fetchEstimates(whereOptions);

        if(rowData.length > 0) {
          // console.log({rowData});
          // データのフォーマット
          const itemData = this.formatRows(rowData);
          this.items = itemData;
          this.estimates = itemData;
        } else {
          // データを取得できなかった際の処理
        }
        // テーブルローディングOFF
        this.tableLoading = false;
      } catch (error) {
        console.error(error);
        // ログ出力
        addLog('ERROR', {
          errorType: MSG.ERROR["002"],
          message: MSG.ERROR["002"],
          errorMessage: error.message,
        })
      }
    },
    // 検索ボタン押下時処理
    async submitSearch() {
      // ローディングON
      this.$store.commit("setLoading", true);

      const form = this.$refs.form;
      if (form.validate()) {
        // すべてのバリデーションが通過したときのみif文の中に入る
        // テーブルローディングON
        this.tableLoading = true;

        this.success = true;

        // データを初期化
        this.items = [];
        this.estimates = [];

        // console.log("バリデーションOK");
        // WHERE条件の作成
        const whereOptions = this.makeWhereOptions();
        // データの取得
        const rowData = await this.fetchEstimates(whereOptions);
        // console.info({rowData});

        if(rowData.length > 0) {
          // データのフォーマット
          const itemData = this.formatRows(rowData);
          this.items = itemData;
          this.estimates = itemData;
        }
        // テーブルローディングOFF
        this.tableLoading = false;
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } else {
        this.success = false;
        // console.log("バリデーションNG");

        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ローディングOFF
        this.$store.commit("setLoading", false);
        return;
      }
    },
    // WHERE句の生成
    makeWhereOptions(initialize = false) {
      let whereOptions = '';

      // 部門コード
      const user = this.$store.getters.user;
      const bumon_cd = user.BUMON_CD;
      // console.log(bumon_cd);
      whereOptions += `AND BUMON_CD = ${bumon_cd} `;
      
      if(initialize) {
        // 初期表示の場合
        whereOptions += `AND MITSUMORI_DATE BETWEEN '${this.mitsumoriDate[0]}' AND '${this.mitsumoriDate[1]}' `;
        whereOptions += `AND SAKUSEI_DATE BETWEEN '${this.sakuseiDate[0]}' AND '${this.sakuseiDate[1]}' `;
      } else {
        // 初期表示でない場合
        // 見積日付を昇順ソートする
        this.mitsumoriDate.sort((a, b) => new Date(a) - new Date(b));
        switch (this.mitsumoriDate.length) {
          // 見積日付
          case 1:
            whereOptions += `AND MITSUMORI_DATE = '${this.mitsumoriDate[0]}' `;
            break;
          case 2:
            whereOptions += `AND MITSUMORI_DATE BETWEEN '${this.mitsumoriDate[0]}' AND '${this.mitsumoriDate[1]}' `;
            break;
          default:
            break;
        }
        // 作成日付を昇順ソートする
        this.sakuseiDate.sort((a, b) => new Date(a) - new Date(b));
        switch (this.sakuseiDate.length) {
          // 作成日付
          case 1:
            whereOptions += `AND SAKUSEI_DATE = '${this.sakuseiDate[0]}' `;
            break;
          case 2:
            whereOptions += `AND SAKUSEI_DATE BETWEEN '${this.sakuseiDate[0]}' AND '${this.sakuseiDate[1]}' `;
            break;
          default:
            break;
        }
        // 見積番号開始
        if (this.mitsumoriNoFrom) {
          if (this.mitsumoriNoTo) {
            whereOptions += `AND MITSUMORI_NO >= ${this.mitsumoriNoFrom} `;
          } else {
            // 見積番号終了が未入力の場合は完全一致検索
            whereOptions += `AND MITSUMORI_NO = ${this.mitsumoriNoFrom} `;
          }
        }
        // 見積番号終了
        if (this.mitsumoriNoTo) {
          if (this.mitsumoriNoFrom) {
            whereOptions += `AND MITSUMORI_NO <= ${this.mitsumoriNoTo} `;
          } else {
            // 見積番号開始が未入力の場合は完全一致検索
            whereOptions += `AND MITSUMORI_NO = ${this.mitsumoriNoTo} `;
          }
        }
        // 得意先名
        if (this.tokuisakiName) {
          whereOptions += `AND CSS_TOKUISAKI_NAME LIKE '%${this.tokuisakiName}%' `;
        }
        // 件名
        if (this.kenmeiName) {
          whereOptions += `AND KENMEI_NAME LIKE '%${this.kenmeiName}%' `;
        }
      }
      whereOptions += `ORDER BY MITSUMORI_DATE DESC, MITSUMORI_NO DESC, MEISAI_NO`;

      return whereOptions;
    },
    // APIを実行し、見積データを取得
    async fetchEstimates(whereOptions) {
      // console.log({whereOptions});
      let condition = { where_options: whereOptions };
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(graphqlOperation(listV_MITSUMORI_DATA_WITH_MEISAI, condition));
        // console.log({result});
        
        let resultData = [];
        if(result.data.listV_MITSUMORI_DATA_WITH_MEISAI) {
          // データを取得できた際の処理
          resultData = result.data.listV_MITSUMORI_DATA_WITH_MEISAI;
        } 
        return resultData;
      } catch (error) {
        console.error(error);
        // ログ出力
        addLog('ERROR', {
          errorType: MSG.ERROR["002"],
          message: MSG.ERROR["002"],
          errorMessage: error.message,
        })
        throw error;
      }
    },
    // APIで取得したデータをフォーマット
    formatRows(rows) {
      let items = [];
      for (const row of rows) {
        const item = {
          itemKey: `${row.MITSUMORI_DATA_ID}-${row.MEISAI_NO}`,
          mitsumoriDataId: row.MITSUMORI_DATA_ID,
          bumonCd: row.BUMON_CD,
          mitsumoriNo: row.MITSUMORI_NO,
          meisaiNo: row.MEISAI_NO,
          mitsumoriDate: row.MITSUMORI_DATE,
          sakuseiDate: row.SAKUSEI_DATE,
          kenmeiName: row.KENMEI_NAME ? row.KENMEI_NAME : '',
          yukokigenSu: row.YUKOKIGEN_SU,
          nokiSu: row.NOKI_SU,
          tokuisakiCd: row.CSS_TOKUISAKI_CD,
          tokuisakiName: row.CSS_TOKUISAKI_NAME,
          tekiyo: row.TEKIYO,
          loginUserCd: row.LOGIN_USER_CD,
          tantoshaCd: row.TANTOSHA_CD,
          tantoshaName: row.TANTOSHA_NAME,
          shiiresakiCd: row.SHIIRESAKI_CD,
          cssShiiresakiCd: row.CSS_SHIIRESAKI_CD,
          shohinCd: row.SHOHIN_CD,
          katabanNo: row.KATABAN_NO,
          shiiresakiName: row.SHIIRESAKI_NAME,
          shiiresakiKanaName: row.SHIIRESAKI_KANA_NAME,
          shohinName: row.SHOHIN_NAME,
          genkaGk: row.GENKA_GK,
          tankaGk: row.TANKA_GK,
          tekaGk: row.TEKA_GK,
          suryoSu: row.SURYO_SU,
          kingakuGk: row.KINGAKU_GK,
          toshinNetGk: row.TOSHIN_NET_GK,
          genkaKingakuGk: row.SURYO_SU * row.GENKA_GK,
          biko: row.BIKO,
          comment: row.COMMENT,
          panasonicKbn: row.PANASONIC_KBN,
          tesuryoKbn: row.TESURYO_KBN,
          toshinKeiyuKbn: row.TOSHIN_KEIYU_KBN,
          shiiresakiRebateKbn: row.SHIIRESAKI_REBATE_KBN,
        }
        items.push(item);
      }
      return items;
    },
    // 発注簿転送処理ボタン
    transfer() {
      alert(
        "保存されていない場合はエラー。保存されていれば発注簿転送処理を実施し、結果メッセージを表示する。"
      );
      // console.log("●印刷処理");
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.scroll-lock-estimate-search table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-search table td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.scroll-lock-estimate-search table th:nth-child(2) {
  position: sticky;
  left: 80px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-search table td:nth-child(2) {
  position: sticky;
  left: 80px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.scroll-lock-estimate-search table th:nth-child(3) {
  position: sticky;
  left: 160px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.scroll-lock-estimate-search table td:nth-child(3) {
  position: sticky;
  left: 160px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
.v-text-field {
  padding-top: 8px;
  margin-top: 0;
}
</style>