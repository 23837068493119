<template>
  <div>
    <!-- ●TODO sortablejs等で行をドラッグアンドドロップで入れ替えできないか？ -->
    <v-data-table
      dense
      :headers="headers"
      :items="estimates"
      :footer-props="footerProps"
      :items-per-page="100"
      class="elevation-5 mt-5"
      v-model="selected"
      item-key="itemKey"
      show-select
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      fixed-header
      height="545"
      multi-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>検索結果</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- EstimateSearchから受け取った見積データ件数 -->
          <v-toolbar-title> {{ estimates.length }}件 </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- 発注簿転送ボタン -->
          <v-btn
            class="mb-2 mx-2"
            dark
            color="secondary"
            @click="confirmTransfer"
            rounded
          >
            <v-icon left>mdi-transfer</v-icon>発注簿転送
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="confirmDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">確認</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                発注簿に転送します。よろしいですか？
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="secondary" rounded @click="confirmDialog = false">
                キャンセル
              </v-btn>
              <v-btn dark color="secondary" rounded @click="submitTransfer"> OK </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="completeDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">完了</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                転送完了しました。
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="secondary" rounded @click="completeDialog = false, selected = []"> OK </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="errorDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">エラー</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container
                style="white-space: pre-wrap;"
                v-text="errorText"
              >
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="secondary" rounded @click="errorDialog = false"> OK </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- 操作 -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="m-0"
              fab
              dark
              x-small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>修正</span>
        </v-tooltip>
      </template>
      <!-- 単価 -->
      <template v-slot:[`item.tankaGk`]="{ item }">
        {{ item.tankaGk == null || item.tankaGk == "" ? item.tankaGk : new Number(item.tankaGk).toLocaleString() }}
      </template>
      <!-- 金額 -->
      <template v-slot:[`item.kingakuGk`]="{ item }">
        {{ item.kingakuGk == null || item.kingakuGk == "" ? item.kingakuGk : new Number(item.kingakuGk).toLocaleString() }}
      </template>
      <!-- トシンネット -->
      <template v-slot:[`item.toshinNetGk`]="{ item }">
        {{ item.toshinNetGk == null || item.toshinNetGk == "" ? item.toshinNetGk : new Number(item.toshinNetGk).toLocaleString() }}
      </template>
      <!-- 原価 -->
      <template v-slot:[`item.genkaGk`]="{ item }">
        {{ item.genkaGk == null || item.genkaGk == "" ? item.genkaGk : new Number(item.genkaGk).toLocaleString() }}
      </template>
      <!-- 原価金額 -->
      <template v-slot:[`item.genkaKingakuGk`]="{ item }">
        {{ item.genkaKingakuGk == null || item.genkaKingakuGk == "" ? item.genkaKingakuGk : new Number(item.genkaKingakuGk).toLocaleString() }}
      </template>
      <!-- 定価 -->
      <template v-slot:[`item.tekaGk`]="{ item }">
        {{ item.tekaGk == null || item.tekaGk == "" ? item.tekaGk : new Number(item.tekaGk).toLocaleString() }}
      </template>
      <!-- データなし -->
      <template v-slot:no-data>
        該当するデータが存在しません
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { getT_CHOHYO_COUNTER } from '@/graphql/queries';
import { executeTransactSql } from '@/graphql/mutations';
import { addLog } from '@/assets/js/logger';
import { CUSTOM_FOOTER_PROPS_SEARCH_AREA } from "@/assets/js/const";
import { executeHanbaikanriSqls } from '@/assets/js/common';
import { ALERT_UNSELECTED_DATA, ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "EstimateSearchDataTable",

  props: ['estimates', 'tableLoading'],

  data: () => ({
    // チェックボックス
    selected: [],
    // Datatablesのヘッダー
    headers: [
      { text: "", value: "data-table-select", width: "50px" },
      { text: "操作", value: "actions", sortable: false, width: "42px" },
      { text: "見積番号", value: "mitsumoriNo", width: "40px" },
      { text: "明細No", value: "meisaiNo", width: "40px", align: "start" },
      { text: "見積日付", value: "mitsumoriDate", width: "85px" },
      { text: "品名", value: "shohinName", width: "200px" },
      { text: "商品コード", value: "shohinCd", width: "80px" },
      { text: "型番", value: "katabanNo", width: "120px" },
      { text: "数量", value: "suryoSu", width: "50px" },
      { text: "単価", value: "tankaGk", width: "50px" },
      { text: "作成日付", value: "sakuseiDate", width: "85px" },
      { text: "得意先コード", value: "tokuisakiCd", width: "100px" },
      { text: "得意先", value: "tokuisakiName", width: "200px" },
      { text: "件名", value: "kenmeiName", width: "100px" },
      { text: "仕入先コード", value: "shiiresakiCd", width: "50px" },
      { text: "仕入先名カナ", value: "shiiresakiKanaName", width: "150px" },
      { text: "仕入先名", value: "shiiresakiName", width: "100px" },
      { text: "金額", value: "kingakuGk", width: "50px" },
      { text: "トシンネット", value: "toshinNetGk", width: "50px" },
      { text: "原価", value: "genkaGk", width: "30px" },
      { text: "原価金額", value: "genkaKingakuGk", width: "40px" },
      { text: "定価", value: "tekaGk", width: "30px" },
      { text: "備考", value: "biko", width: "200px" },
      { text: "コメント", value: "comment", width: "200px" },
    ],
    // Datatablesのデータ
    items: [],
    // 編集インデックス
    editedIndex: -1,

    // 発注簿転送確認ダイアログ表示フラグ
    confirmDialog: false,
    // 発注簿転送完了ダイアログ表示フラグ
    completeDialog: false,
    // 伝票区分
    denpyoKbn: 1,

    // エラーダイアログ表示フラグ
    errorDialog: false,
    errorText: 'エラーが発生しました。\nしばらくしてから、もう一度お試しください。',
    // テーブルフッターオプション
    footerProps: CUSTOM_FOOTER_PROPS_SEARCH_AREA,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大2文字
    limit_length2: (value) =>
      value.length <= 2 || "2文字以内で入力してください",
    // 文字数の制約 最大8文字
    limit_length8: (value) =>
      value.length <= 8 || "8文字以内で入力してください",
    // 文字数の制約 最大13文字
    limit_length13: (value) =>
      value.length <= 13 || "13文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大40文字
    limit_length40: (value) =>
      value.length <= 40 || "40文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 最大80文字
    limit_length80: (value) =>
      value.length <= 80 || "80文字以内で入力してください",
    // 文字数の制約 最大150文字
    limit_length150: (value) =>
      value.length <= 150 || "150文字以内で入力してください",
  }),

  computed: {},

  watch: {},

  created() {
  },

  methods: {
    // 編集ボタン押下時処理
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      // 入力画面に編集対象の見積番号を渡す
      this.$router.push({
        name: "EstimateInput",
        query :{ reqMitsumoriDataId: this.editedItem.mitsumoriDataId, },
      });
    },

    // 発注簿転送ボタン押下時処理
    confirmTransfer() {
      // メソッド名を定義
      const method_name="confirmTransfer"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (this.selected.length == 0) {
        alert(ALERT_UNSELECTED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 確認ダイアログを開く
      this.confirmDialog = true;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    // 発注簿転送ダイアログOKボタン押下時処理
    async submitTransfer() {
      // ローディングON
      this.$store.commit("setLoading", true);
      this.confirmDialog = false;

      // 転送するデータをフォーマット
      const formatedItems = await this.formatTransferringData(this.selected);

      // 発注データ・発注明細データテーブルに登録
      const result = await this.insertTable(formatedItems);

      if (result) {
        this.completeDialog = true;
      } else {
        this.errorDialog = true;
      }
      // ローディングOFF
      this.$store.commit("setLoading", false);
    },
    // 転送するデータを整形・必要な追加データを取得
    async formatTransferringData(items) {
      console.log('formatTransferringData');
      console.log({items});

      // メソッド名を定義
      const method_name="formatTransferringData";

      // 仕入先ごとに発注データをまとめるので、仕入先コード・明細NOでソート
      let sortedItems = items.slice().sort((a, b) => {
        if (a.shiiresakiCd > b.shiiresakiCd) return 1;
        if (a.shiiresakiCd < b.shiiresakiCd) return -1;
        if (a.meisaiNo > b.meisaiNo) return 1;
        if (a.meisaiNo < b.meisaiNo) return -1;
      })
      console.log({sortedItems});

      let hanbaikanriSqls = [];

      /* 販売管理DBから商品情報と得意先情報を取得 */
      // SHOHINテーブルデータ取得SQL作成
      // 商品コードを抜き出し
      const originalShohinCdList = sortedItems.map(function(item) {
        return item.shohinCd;
      });
      // 重複を削除してIN句の文字列を作成
      const shohinCdList = Array.from(new Set(originalShohinCdList))
      const shohinCds = shohinCdList.join(',');
      // 商品コードに紐づくデータを取得するSQL
      hanbaikanriSqls.push(`SELECT SH_SHOCD, SH_SHOKNM, SH_SHONM, SH_SIRTNA, SH_JYOTNA, SH_JAN FROM SHOHIN  WHERE SH_SHOCD IN (${shohinCds})`);

      // TOKUIテーブルデータ取得SQL作成
      // 得意先コードを抜き出し
      const originalTokuisakiCdList = sortedItems.map(function(item) {
        return item.tokuisakiCd;
      });
      // 重複を削除してIN句の文字列を作成
      const tokuisakiCdList = Array.from(new Set(originalTokuisakiCdList))
      const tokuisakiCds = tokuisakiCdList.join(',');
      hanbaikanriSqls.push(`SELECT TK_TORCD, TK_TANCD FROM TOKUI WHERE TK_TORCD IN (${tokuisakiCds})`);

      let hanbaikanriResult = await executeHanbaikanriSqls(this.$route.name, method_name, hanbaikanriSqls);

      let resultShohinData = null;
      let resultTokuiData = null;
      if (!hanbaikanriResult.body.error) {
        // 商品リスト
        resultShohinData = hanbaikanriResult.body.data[0];
        // 得意先リスト
        resultTokuiData = hanbaikanriResult.body.data[1];
      } else {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // オートコンプリートのローディングを解除
        this.isLoadingClient = false;
        return;
      }

      // 追加プロパティが付与された見積データリスト
      let formatedItems = []
      // 前レコードの仕入先コード
      let previousShiiresakiCd = null;
      // 帳票カウンタUPDATE用文言
      let sqlCounterTables;
      // 帳票カウンタから取得した番号→注文番号となる
      let counterNo;

      // 部門コード
      const user = this.$store.getters.user;
      let counterCondition = {
        BUMON_CD: user.BUMON_CD,
      };

      for (const item of sortedItems) {
        // 仕入先コードが前のレコードと異なる場合、帳票カウンタデータを取得して注文番号を更新
        if(previousShiiresakiCd !== item.shiiresakiCd) {
          try {
            let result = await API.graphql(
              graphqlOperation(getT_CHOHYO_COUNTER, counterCondition)
            );
            // 結果判定
            if (result.data.getT_CHOHYO_COUNTER) {
              // データを取得できた場合
              let resultData = result.data.getT_CHOHYO_COUNTER;
              // 通常用カウンタが最大値（99999）到達時は、1から採番
              counterNo =
                resultData.HACCHUSHO_NO === 99999
                  ? 1
                  : resultData.HACCHUSHO_NO + 1;
              sqlCounterTables = `HACCHUSHO_NO = ${counterNo},`;
            } else {
              if (result.errors) {
                // データを取得できなかった場合
                // ログ出力(SQL実行でエラー)
                // console.log({result});
                addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CHOHYO_COUNTER", where_option:counterCondition}, this.$route.name, method_name, TYPE["0003"],);
                return false;
              } else {
                // データ0件時
                return false;
              }
            }
          } catch (error) {
            // console.log({error});
            // ログ出力(exception)
            addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:counterCondition}, this.$route.name, method_name, TYPE["0003"], error);
            return false;
          }

          // カウンタテーブルの値を更新
          const updateSqlList = { SQLs: [
            `UPDATE T_CHOHYO_COUNTER SET ` +
              sqlCounterTables +
              ` UPDATE_DATE = CURDATE(),` +
              ` UPDATE_USER_NAME = '${user.LOGIN_USER_CD}'`+
              ` WHERE BUMON_CD = '${user.BUMON_CD}';`
          ] };

          try {
            // 帳票カウンタ更新
            let result = await API.graphql(
              graphqlOperation(executeTransactSql, updateSqlList)
            );

            // レスポンスデータを取得できた際の処理
            if (result) {
              const responseBody = JSON.parse(result.data.executeTransactSql.body);

              // SQL実行でエラーが発生した場合の処理
              if (result.data.executeTransactSql.statusCode > 200) {
                // レスポンスメッセージ
                // let message = responseBody.message;
                // // エラー内容
                const errorMessage = responseBody.error;
                console.error({ errorMessage });
                // ログ出力(Lamda関数でのSQL実行でステータスエラー)
                addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:updateSqlList}, this.$route.name, method_name, TYPE["0003"],);
                return false;
              }
            } else {
              // レスポンスデータを取得できなかった際の処理
              // console.log("insertTable：エラー2 : " + JSON.stringify(result));
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:updateSqlList}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            }
          } catch (error) {
            // console.log("insertTable : 異常終了 = " + { error });
            // ログ出力(exception)
            addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:updateSqlList}, this.$route.name, method_name, TYPE["0003"], error);
            return false;
          }
          // 仕入先コードを保管
          previousShiiresakiCd = item.shiiresakiCd;
        }
        // 注文番号をセット
        item.chumonNo = counterNo;

        // 見積テーブルのトシン情報を設定（トシン経由の場合）
        if (item.toshinKeiyuKbn == 1) {
          // 発注番号－注文番号
          item.toshinJuhacchuNo =
              item.departmentOrderNo + "－" + item.chumonNo;
          if (item.chumonNo !== null && item.toshinJuhacchuNo !== null) {
            // 担当者名[全角スペース]発注番号－注文番号
            item.toshinHacchushaChumonNo = item.tantoshaName + "　" + item.toshinJuhacchuNo;
          }
        } else {
          item.toshinJuhacchuNo = null;
          item.toshinHacchushaChumonNo = null;
        }

        // リベートフラグ(リベート区分と手数料区分を用いて判定)
        item.rebateFlg = false;
        switch (item.shiiresakiRebateKbn) {
          case 1:
            // リベート区分が1の場合true
            item.rebateFlg = true;
            break;
          case 2:
            // リベート区分が2の場合、手数料区分が1以外の場合true
            if (item.tesuryoKbn !== 1) item.rebateFlg = true;
            break;
          default:
            break;
        }

        // 入荷時仕入価格を販売管理SHOHINテーブルのSH_SIRTNAから取得
        item.nyukajiShiireKakakuGk = null;
        let shohin = resultShohinData.find(
          (items) => items.SH_SHOCD == item.shohinCd
        );
        if (shohin !== void 0) {
          item.nyukajiShiireKakakuGk = shohin.SH_SIRTNA;
        }

        // 売上担当者コードを販売管理TOKUIテーブルのSH_SIRTNAから取得
        item.cssUriageTantoshaCd = null;
        let tokui = resultTokuiData.find(
          (items) => items.TK_TORCD == item.tokuisakiCd
        );
        if (tokui !== void 0) {
          item.cssUriageTantoshaCd = tokui.TK_TANCD;
        }

        formatedItems.push(item);

      }
      console.log({formatedItems});
      return formatedItems;
    },
    // 新規登録処理
    async insertTable(items) {
      // メソッド名を定義
      const method_name="insertTable"

      // ログインユーザー
      const user = this.$store.getters.user;

      // 当日日付を取得
      let today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString().substr(0, 10);

      // 前レコードの仕入先コード
      let previousShiiresakiCd = null;
      // 明細番号
      let meisaiNo = 1;

      // INSERTSQLのリスト
      let sqlList = [];

      for (const item of items) {
        console.log({previousShiiresakiCd});
        console.log(item.shiiresakiCd);
        console.log({meisaiNo});
        // 仕入先コードが前のレコードと異なる場合、発注データを作成
        if(previousShiiresakiCd !== item.shiiresakiCd) {
          // 仕入先コードを保管
          previousShiiresakiCd = item.shiiresakiCd;
          // 明細番号を初期化
          meisaiNo = 1;

          // 発注データ用SQLを追加
          let loginUserCd = !item.loginUserCd
            ? null
            : `'${item.loginUserCd}'`;
          let tantoshaName = !item.tantoshaName
            ? null
            : `'${item.tantoshaName}'`;
          let toshinJuhacchuNo = !item.toshinJuhacchuNo
          ? null
          : `'${item.toshinJuhacchuNo}'`;
          let toshinHacchushaChumonNo = !item.toshinHacchushaChumonNo
            ? null
            : `'${item.toshinHacchushaChumonNo}'`;
          let tekiyo = !item.tekiyo
            ? null
            : `'${item.tekiyo}'`;
          sqlList.push(
            `INSERT INTO T_HACCHU_DATA (` +
            ` BUMON_CD,` +
            ` PANASONIC_KBN,` +
            ` CHUMON_NO,` +
            ` CHUMON_DATE,` +
            ` SHIIRESAKI_CD,` +
            ` SHIIRESAKI_NAME,` +
            ` TESURYO_KBN,` +
            ` TOSHIN_KEIYU_KBN,` +
            ` TOSHIN_JUHACCHU_NO,` +
            ` TOSHIN_HACCHUSHA_CHUMON_NO,` +
            ` LOGIN_USER_CD,` +
            ` TANTOSHA_CD,` +
            ` TANTOSHA_NAME,` +
            ` TEKIYO,` +
            ` CREATE_DATE,` +
            ` CREATE_USER_NAME,` +
            ` UPDATE_DATE,` +
            ` UPDATE_USER_NAME` +
            `) VALUES (` +
              `${item.bumonCd},` + //BUMON_CD
              `${item.panasonicKbn},` + //PANASONIC_KBN
              `${item.chumonNo},` + //CHUMON_NO
              `'${today}',` + //CHUMON_DATE
              `${item.shiiresakiCd},` + //SHIIRESAKI_CD
              `'${item.shiiresakiName}',` + //SHIIRESAKI_NAME
              `${item.tesuryoKbn},` + //TESURYO_KBN
              `${item.toshinKeiyuKbn},` + //TOSHIN_KEIYU_KBN
              `${toshinJuhacchuNo},` + //TOSHIN_JUHACCHU_NO
              `${toshinHacchushaChumonNo},` + //TOSHIN_HACCHUSHA_CHUMON_NO
              `${loginUserCd},` + //LOGIN_USER_CD
              `${item.tantoshaCd},` + //TANTOSHA_CD
              `${tantoshaName},` + //TANTOSHA_NAME
              `${tekiyo},` + //TEKIYO
              "CURDATE()," + //CREATE_DATE
              `'${user.LOGIN_USER_CD}',` + //CREATE_USER_NAME
              "CURDATE()," + //UPDATE_DATE
              `'${user.LOGIN_USER_CD}');` //UPDATE_USER_NAME
          );
        }
        
        // 発注明細データ用SQLを追加
        let biko = !item.biko
          ? null
          : `'${item.biko}'`;
        let tokuisakiName = !item.tokuisakiName ? null : `'${item.tokuisakiName}'`;
        let cssUriageTantoshaCd= !item.cssUriageTantoshaCd
          ? null
          : `'${item.cssUriageTantoshaCd}'`;
        sqlList.push(
          `INSERT INTO T_HACCHU_MEISAI_DATA (` +
            `HACCHU_DATA_ID, ` +
            `MEISAI_NO, ` +
            `SHOHIN_CD, ` +
            `HINBAN_NO, ` +
            `SHOHIN_NAME, ` +
            `SURYO_SU, ` +
            `NET_GK, ` +
            `SAISHU_SHIIRE_KAKAKU_GK, ` +
            `OKYAKUSAMA_OROSHIKAKAKU_GK, ` +
            `NYUKAJI_SHIIRE_KAKAKU_GK, ` +
            `CSS_TOKUISAKI_CD, ` +
            `CSS_TOKUISAKI_NAME, ` +
            `BIKO, ` +
            `NYUKA_FLG, ` +
            `KARIDEN_FLG, ` +
            `HONDEN_FLG, ` +
            `TANI_NAME, ` +
            `KARIDEN_INSATSU_FLG, ` +
            `REBATE_FLG, ` +
            `CSS_URIAGE_TANTOSHA_CD, ` +
            `JIKAI_SEIKYU_FLG, ` +
            `TOSHIN_DENPYO_INSATSU_FLG, ` +
            `URIAGE_CSV_FLG, ` +
            `SHIIRE_CSV_FLG, ` +
            `SHIIRE_MACHI_CHECK_FLG, ` +
            `GENKA_IRAI_FLG, ` +
            `CANCEL_FLG, ` +
            `EMAIL_SENT_FLG, ` +
            `HENPIN_STATUS_KBN, ` +
            `DENPYO_KBN, ` +
            `HIKIAGE_FLG, ` +
            `IRAI_FLG, ` +
            `HENKYAKU_FLG, ` +
            `SHIIRE_AKA_FLG, ` +
            `URIAGE_AKA_FLG, ` +
            `CREATE_DATE, ` +
            `CREATE_USER_NAME, ` +
            `UPDATE_DATE, ` +
            `UPDATE_USER_NAME ` +
            `) VALUES (` +
            `LAST_INSERT_ID(),` + //HACCHU_DATA_ID
            `${meisaiNo},` + //MEISAI_NO
            `${item.shohinCd},` + //SHOHIN_CD
            `'${item.katabanNo}',` + //HINBAN_NO
            `'${item.shohinName}',` + //SHOHIN_NAME
            `${item.suryoSu},` + //SURYO_SU
            `${item.toshinNetGk},` + //NET_GK
            `${item.genkaGk},` + //SAISHU_SHIIRE_KAKAKU_GK
            `${item.tankaGk},` + //OKYAKUSAMA_OROSHIKAKAKU_GK
            `${item.nyukajiShiireKakakuGk},` + //NYUKAJI_SHIIRE_KAKAKU_GK
            `${item.tokuisakiCd},` + //CSS_TOKUISAKI_CD
            `${tokuisakiName},` + //CSS_TOKUISAKI_NAME
            `${biko},` + //BIKO
            `0,` + //NYUKA_FLG
            `0,` + //KARIDEN_FLG
            `0,` + //HONDEN_FLG
            `'個',` + //TANI_NAME
            `0,` + //KARIDEN_INSATSU_FLG
            `${Number(item.rebateFlg)},` + //REBATE_FLG
            `${cssUriageTantoshaCd},` + //CSS_URIAGE_TANTOSHA_CD
            `0,` + //JIKAI_SEIKYU_FLG
            `0,` + //TOSHIN_DENPYO_INSATSU_FLG
            `0,` + //URIAGE_CSV_FLG
            `0,` + //SHIIRE_CSV_FLG
            `0,` + //SHIIRE_MACHI_CHECK_FLG
            `0,` + //GENKA_IRAI_FLG
            `0,` + //CANCEL_FLG
            `0,` + //EMAIL_SENT_FLG
            `0,` + //HENPIN_STATUS_KBN
            `${this.denpyoKbn},` + //DENPYO_KBN
            `0,` + //HIKIAGE_FLG
            `0,` + //IRAI_FLG
            `0,` + //HENKYAKU_FLG
            `0,` + //SHIIRE_AKA_FLG
            `0,` + //URIAGE_AKA_FLG
            `CURDATE(),` + //CREATE_DATE
            `'${user.LOGIN_USER_CD}',` + //CREATE_USER_NAME
            `CURDATE(),` + //UPDATE_DATE
            `'${user.LOGIN_USER_CD}');` //UPDATE_USER_NAME
        );
        meisaiNo++;
      }
      console.log({sqlList});

      // SQL実行
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, { SQLs: sqlList })
        );

        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // レスポンスメッセージ
            // let message = responseBody.message;
            // // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:sqlList}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // console.log("insertTable：エラー2 : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:sqlList}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // console.log("insertTable : 異常終了 = " + { error });
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:sqlList}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // console.log("insertTable : updating... ");
      return true;
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.v-data-table >>> th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.v-data-table >>> th:nth-child(3) {
  position: sticky;
  left: 92px; /* 前列までの列幅指定 */
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(3) {
  position: sticky;
  left: 92px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 4列目スクロール時固定 */
.v-data-table >>> th:nth-child(4) {
  position: sticky;
  left: 132px; /* 前列までの列幅指定 */
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(4) {
  position: sticky;
  left: 132px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 5列目スクロール時固定 */
.v-data-table >>> th:nth-child(5) {
  position: sticky;
  left: 172px; /* 前列までの列幅指定 */
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(5) {
  position: sticky;
  left: 172px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 6列目スクロール時固定 */
.v-data-table >>> th:nth-child(6) {
  position: sticky;
  left: 257px; /* 前列までの列幅指定 */
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(6) {
  position: sticky;
  left: 257px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
</style>
